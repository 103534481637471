export var tableField = [
  { selectType: 'selection', width: '', type: 'input' },
  { label: '附件编号', code: 'code', type: 'input' },
  {
    label: '附件类型',
    code: 'fileType',
    type: 'function',
    width: '80px',
    handle: function (index, row) {
      return row.fileType === 1 ? '工厂直通率' : row.fileType === 2 ? '质量协议' : row.fileType === 3 ? 'RMA数据'
        : row.fileType === 4 ? '出货检验报告' : row.fileType === 5 ? '质量体系资质' : row.fileType
    }
  },
  { label: '附件说明', code: 'bz', type: 'input', width: '200%' },
  {
    label: '时间范围',
    type: 'function',
    width: '90px',
    handle: function (index, row) {
      return row.timeScopeStartTime + '~' + row.timeScopeEndTime
    }
  },
  {
    label: '附件查看',
    code: 'act',
    width: '80px',
    type: 'action',
    actionData: [
      {
        actionLabel: '查看',
        operationButton: 'see'
      }
    ]
  }
]

export var tableData = [
]

export var rules = {
  fileType: [
    { required: true, message: '请选择附件类型', trigger: 'change' }
  ],
  ScopeTime: [
    { required: true, message: '请选择时间', trigger: 'change' }
  ],
  fileList: [
    { required: true, message: '请上传附件', trigger: 'change' }
  ]
}
