<template>
  <div class="mainform showZlFileList">
    <div class="form">
      <el-form class="content bascform" ref="addfilelidt" disabled :model="form" label-width="120px">
        <div class="col col8">
          <el-form-item label="附件编号" prop="fileList">
            {{formData1.code}}
          </el-form-item>
        </div>
        <div class="col col8">
          <el-form-item label="供应商名称" prop="fileList">
            {{formData1.supplierName}}
          </el-form-item>
        </div>
        <div class="col col8">
          <el-form-item label="附件类型" prop="fileList">
            {{formData1.fileType === 1 ? '工厂直通率' : formData1.fileType === 2 ? '质量协议' : formData1.fileType === 3 ? 'RMA数据'
        : formData1.fileType === 4 ? '出货检验报告' : formData1.fileType === 5 ? '质量体系资质' : formData1.fileType}}
          </el-form-item>
        </div>
        <div class="col col8">
          <el-form-item label="附件说明" prop="fileList">
            {{formData1.bz}}
          </el-form-item>
        </div>
        <div class="col col8">
          <el-form-item label="附件" prop="fileList">
            <el-upload :action="actionURL" :on-preview="handlePreview" :on-remove="handleRemove"
              :before-remove="beforeRemove" :headers="tokenHeader" :on-success="handleSuccess" multiple
              :file-list="form.fileList">
            </el-upload>
          </el-form-item>
        </div>
      </el-form>
    </div>
  </div>
</template>
<script>
import { baseHost } from '@/assets/js/PublicData.js'
import Component from '@/assets/js/components.js'
import { fileHandle } from '@/assets/js/FileAction.js'
import { rules } from './js/setting.js'
import dayjs from 'dayjs'
export default {
  name: 'ShowFileList',
  components: { ...Component },
  props: ['formData', 'formData1'],
  data: function () {
    return {
      form: this.formData,
      actionURL: baseHost.SupplierRegHost + '/api/file/m/file/uploadToServer',
      rules: rules,
      tokenHeader: { token: localStorage.getItem('token') }
    }
  },
  methods: {
    dateChange (date) {
      this.form.timeScopeStartTime = dayjs(date[0]).format('YYYY-MM-DD')
      this.form.timeScopeEndTime = dayjs(date[1]).format('YYYY-MM-DD')
    },
    handlePreview (file) {
      fileHandle.handlePreview(file, true)
    },
    beforeRemove (file, fileList) {
      return this.$confirm(`确定移除 ${file.name}？`)
    },
    handleSuccess (response, file, fileList) {
      if (response.code === '200') {
        response.data.forEach(element => {
          this.form.fileList.push(element)
        })
      }
    },
    handleRemove (file) {
      for (var i = 0; i < this.form.fileList.length; i++) {
        if (this.form.fileList[i].id === file.id) {
          this.form.fileList.splice(i, 1)
        }
      }
    }
  }
}
</script>

<style scoped lang="scss">
  @import '@/assets/css/tableList.scss';
  @import '@/assets/css/elForm.scss';
  .showZlFileList {
    .form {
      padding: 0px 0px;
    }
    :deep(.el-form-item){
      .el-upload--text{
        display: none;
      }
    }
  }
</style>
